@import "../../commonStyle.scss";

.container {
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  flex-direction: column;

  .button {
    margin: 30px;
  }
}
