@import "../../commonStyle.scss";
.card {
  display: flex;
  justify-content: center;
  background-color: $backgroundColor;
  align-items: flex-start;
  .buttons {
    margin-top: 20px;
  }
}
