@import "../../commonStyle.scss";

.content {
  @include backgroundPrimary;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 75px;
  align-items: center;

  .info {
    .contentInfo {
      @media (max-width: 750px) {
        flex-direction: column;
      }
      > section {
        margin: 0;
        @media (max-width: 750px) {
          width: 100%;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
      justify-content: center;
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
    > div {
      .miniForm {
        display: flex;
        flex-direction: row;
        gap: 30px;
      }
    }
    .document {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        .date {
          margin-top: 12px;
        }
      }
    }
  }
}
