.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 24;
  background-color: #fff;
  padding: 30px;
}
