@import "../../utils/commonVariable.module.scss";

.breadCrumbsContainer {
  /*https://css.glass */
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: $heightBreadcrumbs;
  overflow-x: scroll;
  .breadcrumbs {
    height: 100%;
    padding: 20px;
  }
}
