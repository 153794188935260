@import "../../utils/commonVariable.module.scss";

.page {
  //background-color: rgba($color: $primaryColor, $alpha: 0.1);
  justify-content: flex-start;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
    @media (max-width: 450px) {
      flex-direction: column;
    }

    .field {
      width: 300px;
      margin: 20px;
    }
  }

  .pageContent {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 345px));
    grid-gap: 40px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, minmax(300px, 345px));
    }
    @media (max-width: 960px) {
      grid-template-columns: repeat(2, minmax(300px, 345px));
    }
    @media (max-width: 650px) {
      grid-template-columns: repeat(1, minmax(300px, 345px));
    }

    margin-bottom: 70px;
  }
  .pagination {
    align-self: center;
  }
}
