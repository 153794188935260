@import "../../utils/commonVariable.module.scss";

.table {
  width: 80%;
  // width: 800px;
  // @media (max-width: 900px) {
  //   width: 600px;
  // }
  // @media (max-width: 640px) {
  //   width: 300px;
  // }
  .green,
  .red,
  .yellow,
  .gray {
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
  }
  .green {
    background-color: $greenTag;
  }
  .red {
    background-color: $redTag;
  }
  .yellow {
    background-color: $yellowTag;
  }
  .gray {
    background-color: $grayTag;
  }
  .textCat {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .column {
    font-weight: bold;
    background-color: $primaryColor;
  }
  .iconColumn {
    width: 10px;
  }
}
