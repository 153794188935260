.modalConfirm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  background-color: white;
  text-align: center;
  border-radius: 8px;
  padding: 25px;
}

.sectionButtonModal {
  display: flex;
  justify-content: space-evenly;
}
