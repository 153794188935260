$borderRadiusVar: 20px;

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeTicket {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
.messagesContainer {
  background-color: #f5f5f5;

  display: flex;
  flex-direction: column;
  gap: 5px;

  margin: auto;
  margin-top: 50px;
  padding: 35px;
  max-width: 800px;
  height: 350px;

  border: 1px black solid;
  border-radius: 10px;

  overflow-y: scroll;
  > div {
    padding: 15px 15px;

    max-width: 400px;
    overflow-wrap: break-word;
  }

  .msnOther {
    border-radius: 5px $borderRadiusVar $borderRadiusVar $borderRadiusVar;
    align-self: flex-start;
    background-color: #ffffff;

    &.msnContinue {
      border-radius: 5px $borderRadiusVar $borderRadiusVar 5px;
    }
  }
  .msnYou {
    border-radius: $borderRadiusVar 5px $borderRadiusVar $borderRadiusVar;
    align-self: flex-end;
    background-color: #e9ebf9;

    &.msnContinue {
      border-radius: $borderRadiusVar 5px 5px $borderRadiusVar;
    }
  }
}
.containerinput {
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .input {
    width: 70%;
  }
}
