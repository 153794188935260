@import "../../utils/commonVariable.module.scss";

.page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -5;
  .frontFace {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    //background-color: rgba($color: #c4c4c4, $alpha: 0.15);
    //box-shadow: inset 0px 25px 50px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: inset 0 -10px 10px -10px #000000;
    -webkit-box-shadow: inset 0 -10px 10px -10px #000000;
    box-shadow: inset 0 -50px 100px -10px rgba(0, 0, 0, 0.1),
      inset 0 50px 100px -10px rgba(0, 0, 0, 0.1);
    // background: rgba(249, 249, 249, 0.15);
    // background: linear-gradient(
    //     90deg,
    //     rgba(249, 249, 249, 1) 10%,
    //     rgba(255, 255, 255, 1) 40%
    //   ),
    //   0.15;
  }
  .verticalLine {
    width: 45%;
    height: 80%;
    background-color: $primaryColor;
    margin-left: 5%;
  }
  .prospectiveLine {
    margin-left: 5%;
    height: 20%;
    width: 45%;
    background-color: $primaryColor;
    transform: perspective(400px) rotateX(45deg);
    transform-origin: 70% 0%;
  }
}
