@import "../../commonStyle.scss";

.container {
  @include backgroundPrimary;

  .title {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .button {
      min-width: 120px;
    }
  }
  .content {
    display: flex;
    //justify-content: space-around;
    flex-direction: column;
    max-width: 1000px;
    align-items: center;
    @media (max-width: 1250px) {
      flex-direction: column;
      align-items: center;
    }
    .form {
      > section {
        @media (max-width: 480px) {
          flex-direction: column;
        }
      }

      .infoAssocia {
        display: flex;
        flex-direction: row;
        gap: 100px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .imgType {
          align-items: center;
          @media (max-width: 768px) {
            align-self: center;
          }
          .imgContainer {
            width: 30vw;
            height: 30vw;
            border: 5px solid #fff;
            border-radius: 50%;
            overflow: hidden;

            @media (max-width: 768px) {
              width: 70vw;
              height: 70vw;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          .principalInfo {
            display: flex;
            flex-direction: row;
            gap: 20px;
            @media (max-width: 480px) {
              flex-direction: column;
            }
            > div {
              width: 100%;
              @media (min-width: 450px) and (max-width: 768px) {
                flex-direction: row;
                width: 50%;
              }
            }
          }
          .otherInfo {
            display: flex;
            flex-direction: column;
            padding-left: 2em;
            width: 100%;
            gap: 10px;
            @media (max-width: 480px) {
              width: auto;
            }
          }
        }
      }

      .secondInformation {
        margin-top: 2em;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 768px) {
          padding-left: 0;
          flex-direction: column;
        }
        .locationDocument {
          display: flex;
          flex-direction: row;
          gap: 20px;
          @media (max-width: 450px) {
            flex-direction: column;
          }
          > div {
            @media (min-width: 451px) and (max-width: 768px) {
              width: 50%;
            }
          }
          .document {
            @media (min-width: 451px) {
              min-height: 328px;
            }
          }
        }
      }
    }
  }
}
