@import "../../commonStyle.scss";
.container {
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .filter {
    margin: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-variant: small-caps;
      border-bottom: 2px solid rgba($color: #000000, $alpha: 0.2);
    }
  }
}
