@import "./utils/commonVariable.module.scss";

.pageContainer {
  min-height: calc(100vh);
  padding: 0px 20px 0px 20px;
  padding-bottom: 50px;
  padding-top: calc($heightNavBar + $heightBreadcrumbs);
}
.pageCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//background HomePage
.background {
  z-index: -1;
  top: -200px;
  left: -400px;
  transform: rotate(45deg);
  position: fixed;
  height: 200%;
  width: 200%;
  background-color: #ffffff;
  opacity: 0.1;
  background-image: linear-gradient(#939393 2px, transparent 2px),
    linear-gradient(to right, #939393 2px, #ffffff 2px);
  background-size: 300px 300px;
}

.cardInfo {
  @include headCardInfo;
  > div {
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2 {
      font-weight: 600;
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;
      padding-bottom: 16px;
    }
    .text {
      color: #8a8a8a;
    }
  }
}

.chipType {
  //#6c441ce5
  background-color: rgba($color: $secondaryColor, $alpha: 0.8);
  color: white;
  font-weight: bold;
}
