@import "../../utils/commonVariable.module.scss";

.container {
  min-width: 60%;
  @include headCardInfo;
  @media (min-width: 750px) and (max-width: 1165px) {
    min-width: 675px;
  }
  @media (max-width: 750px) {
    min-width: 350px;
  }
  .table {
    .rowHead {
      background-color: $primaryColor;
      .head {
        font-weight: bold;
      }
    }
    tr {
      height: 75px;
    }
  }
}
