//navbar, backgroundLine
$primaryColor: #faaf17;

@mixin backgroundPrimary {
  background-color: rgba($color: $primaryColor, $alpha: 0.1);
}

//buttons
$secondaryColor: #374152;
:export {
  secondaryColor: $secondaryColor;
}

//colo background with square pattern
$backgroundColor: #f2f2f2;

$heightBreadcrumbs: 65px;
// :export {
//     heightBreadcrumbs: $heightBreadcrumbs;
//   }

$heightNavBar: 65px;
// :export {
//   heightNavBar: $heightNavBar;
// }

//titolo cardInfo, style per h5
@mixin headCardInfo {
  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #8a8a8a;
    margin: 1em;
    h5 {
      font-weight: bolder;
    }
  }
}

// colors
$greenText: rgb(78, 148, 78);
$greenTag: rgb(122, 180, 122);
$redTag: rgb(186, 117, 117);
$yellowTag: rgb(220, 198, 122);
$grayTag: rgb(220, 220, 220);
