.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: text-bottom;
  border-radius: 10px;
  border: 2px solid #000;
  background-color: #fff;
  padding: 50px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  .inputLables {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .input {
      width: 50%;
    }
  }
  .textFields {
    .price {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .button {
    align-self: center;
  }
}
