@import "../../utils/commonVariable.module.scss";

.card {
  box-shadow: none;
  max-width: 345;
  width: 100%;
  .media {
    height: 200px;
    object-fit: fill;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.cost {
  color: $greenText;
}
