.container {
  min-width: 350px;
  .service {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    @media (min-width: 768px) {
      height: 228px;
    }
  }
  // .table {
  //   margin: 0px 0 50px 0;
  //   .head {
  //     font-weight: bold;
  //   }
  //   tr {
  //     height: 75px;
  //   }
  // }
}
