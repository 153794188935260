@import "../../utils/commonVariable.module.scss";

.navBar {
  height: $heightNavBar;
  background-color: $primaryColor;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: none;
  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo {
      height: 90%;
      cursor: pointer;
    }
    .text {
      text-align: center;
      font-weight: bold;
    }
    .buttons {
      height: 100%;
      display: flex;

      .button {
        :focus-visible {
          background-color: red;
        }
      }

      .selected {
        color: black;
      }
      .divider {
        height: 50%;
        align-self: center;
      }
    }
  }
}
