.icon {
  display: flex;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}
input:disabled {
  color: #000; /* Colore del testo quando è disabilitato */
}

.textInfo {
  span {
    font-size: 13px;
    color: #8a8a8a;
  }
  p {
    color: black;
  }
}
