.card {
  min-height: 400px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  .header {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
  }
  .content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}
