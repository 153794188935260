@import "../../commonStyle.scss";
.container {
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .filter {
    margin: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select {
      min-width: 100px;
    }
  }
  .button {
    align-self: flex-end;
    margin-bottom: 50px;
  }
}
