.page {
  display: flex;
  flex-direction: row;
  padding: 2em;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  max-width: 90%;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: 944px) {
    width: 480px;
    justify-content: center;
  }

  .heroContainer {
    padding: 2em;
    border-radius: 10px;
    background-color: #fff;
    width: fit-content;
    max-width: 300px;
  }
  .hero {
    width: 100%;
  }

  .text {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    min-width: 60%;
    text-align: end;
    @media (max-width: 944px) {
      text-align: start;
      align-items: flex-start;
      width: 100%;
    }

    section {
      width: 80%;
    }

    h3 {
      font-weight: bold;
    }

    button {
      margin: 20px;
    }
  }
}
